<template>
  <div>
    <div v-if="loader || loader_prop">Loading...</div>
    <div v-if="!(loader || loader_prop)">
      <!-- <BackofficeGlobalHeader :global_err="global_err" /> -->
      <!-- <BackofficeHeader :mobile_menu_visible="mobile_menu_visible" :show_mobile_menu="show_mobile_menu" /> -->
      <slot></slot>
      <div v-if="global_err == ''">
   
          <!-- <div id = "main-cont" v-if="!mobile_menu_visible"> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  
</template>

<script>
// import BackofficeMenu from "./BackofficeMenu";
import BackendModel from "../../models/BackendModel";
// import BackofficeGlobalHeader from "./BackofficeGlobalHeader";
// import BackofficeHeader from "./Backoffi?ceHeader";


export default {
  name: "AdminBase",
  props: ["loader_prop"],
  data() {
    return {
      loader: true,
      global_err: "",
      // mobile_menu_visible: true,
    };
  },
  components: {
    // BackofficeMenu,
    // BackofficeGlobalHeader,
    // BackofficeHeader
  },
  methods: {
    show_mobile_menu() {
      console.log("sddsd")
      // this.mobile_menu_visible = !this.mobile_menu_visible;
    },
    // hide_mobile_menu() {
    //   console.log("lll")
    //   this.mobile_menu_visible = false;
    // },
  },
  async mounted() {
    const backendModel = new BackendModel();
    this.loader = true;
    let backoffice_session_id = backendModel.getBackofficeSessionId();
    let authRes = await backendModel.backendRequest(
      "/Api/service/backoffice/auth",
      { backoffice_session_id }
      );
      if (typeof authRes == "undefined") {
        this.global_err = "Backend is down?";
      } else if (authRes.data.res == "ERROR") {
        this.global_err = authRes.data.e;
      } else if (authRes.data.res != "OK") {
        await this.$router.push({ path: "/" });
    }
    this.loader = false;
  },
};
</script>
<style scoped>
@import "./style.css";
</style>
