<template>
  <BackofficeBase>
    <h1>Home</h1>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";

export default {
  name: 'Backoffice',
  components: {
    BackofficeBase
  }
}
</script>
